<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center"></div>
      <v-spacer></v-spacer>
      <v-btn @click="refreshPage" icon>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col class="mb-4">
            <!-- <v-list three-line>
              <template v-for="(item, index) in messages">
                <v-list-item v-if="item.header" :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.header"></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.msg"
                      v-html="item.msg"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>
              </template>
            </v-list> -->
            <v-card
              class="mx-auto"
              max-width="344"
              v-for="(item, index) in messages"
              :key="index"
            >
              <v-card-text v-if="item.header">
                <div>{{ item.header }}</div>
                <div class="text--primary">
                  {{ buildVisitorInfo(item.msg) }}
                </div>
                <v-progress-circular
                  color="deep-purple"
                  height="10"
                  indeterminate
                  v-if="loading && !item.msg"
                ></v-progress-circular>
              </v-card-text>
              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mb-4">
            <v-textarea
              prepend-inner-icon="mdi-image"
              append-icon="mdi-refresh"
              append-outer-icon="mdi-send"
              v-model="message"
              label="Your question"
              filled
              clearable
              auto-grow
              background-color="yellow lighten-5"
              @click:append="reconnectWs"
              @click:append-outer="sendMessage"
              @click:prepend-inner="
                () => {
                  message = '/image ';
                }
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "ChatGPT",

  components: {},

  data: () => ({
    message: "",
    ws: null,
    messages: [],
    chatLast: "",
    loading: false,
  }),

  created() {
    const vm = this;
    vm.wsInit();
  },

  computed: {},

  methods: {
    wsInit() {
      const vm = this;
      // console.log('wsinit--->', vm.BASE_WS)
      vm.ws = new WebSocket(`${vm.BASE_WS}ws/1122`);
      vm.ws.onmessage = vm.wsOnMessage;
      vm.ws.onopen = vm.wsOnOpen;
      vm.ws.onerror = vm.wsOnError;
      vm.ws.onclose = vm.wsOnClose;
    },
    wsOnOpen() {
      const vm = this;
      let content =
        "你好，我是智迎接待员，可以叫我小智。";
      let msg = {
        header: content,
        msg:"很高兴为您服务。请问有什么可以帮助您的？"
      };
      vm.messages.push(msg);
      // console.log(content);
    },
    wsOnError() {
      // const vm = this
      let content = "[CONNECT ERROR]";
      // let msg = {
      //   header: content,
      // }
      // vm.messages.push(msg)
      console.log(content);
    },
    findSecondIndexOf(str, toFind) {
      const index1 = str.indexOf(toFind);
      if (index1 === -1) {
        return -1; // 如果toFind不在str中，则返回-1
      }
      const index2 = str.indexOf(toFind, index1 + toFind.length);
      return index2 === -1 ? -1 : index2; // 如果没有第二个位置，则返回-1
    },
    removeComments(jsonString) {
      // 正则表达式匹配 /* ... */ 和 // ...
      const commentRegex = /\/\/.*$|\/\*[\s\S]*?\*\//gm;
      // 使用空字符串替换所有匹配的注释
      return jsonString.replace(commentRegex, "");
    },
    getJsonFromText(text) {
      // console.log("start-----getjson");
      const jsonRegex =
        /{\s*?"appointment"\s*?:\s*?{\s*?[^]*?\s*?},\s*?"contact"\s*?:\s*?{\s*?[^]*?\s*?}\s*?}/;

      const match = text.match(jsonRegex);

      if (match) {
        const jsonString = match[0];
        // console.log("doit---->", this.removeComments(jsonString));
        return this.removeComments(jsonString);
        // 如果需要将JSON字符串转换为JavaScript对象，可以使用JSON.parse
        // const json = JSON.parse(jsonString);
        // console.log(json);
      } else {
        console.log("No JSON data found.");
        return null;
      }
    },
    wsOnMessage(e) {
      const vm = this;
      // console.log("onMessage", this.getJsonFromText(e.data));
      let jsonString = this.getJsonFromText(e.data);
      let msgRecv = null;
      if (jsonString) {
        msgRecv = JSON.parse(this.getJsonFromText(e.data));
      } else {
        msgRecv = e.data;
      }
      // console.log("msgRecv--->", msgRecv, vm.messages, vm.messages.length);

      // vm.messages.forEach((m, i) => {
      //   // console.log('foreach-->',m,i)
      //   if (!m.msg) {
      //     console.log("foreach-->", m, i);
      //     vm.messages[i].msg = msgRecv;
      //   }
      // });
      vm.messages[vm.messages.length - 1].msg = msgRecv;
      vm.messages.push({ divider: true, inset: true });
      vm.loading = false;
    },
    wsOnClose() {
      const vm = this;
      let content = "[CONNECT CLOSED]";
      let msg = {
        header: content,
      };
      vm.messages.push(msg);
      // console.log(content);
    },
    sendMessage() {
      const vm = this;
      // console.log("send message--->", vm.chatLast, vm.message);
      vm.loading = true;
      if (vm.message !== "") {
        if (vm.chatLast != "") {
          vm.ws.send(vm.message + " " + vm.chatLast);
        } else {
          vm.ws.send(vm.message);
        }
        // console.log(vm.message);
        let msg = {
          header: vm.message,
        };
        vm.messages.push(msg);
        vm.chatLast = vm.message;
        vm.message = "";
      }
    },
    reconnectWs() {
      const vm = this;
      vm.ws.close();
      vm.ws = null;
      vm.wsInit();
    },
    refreshPage() {
      window.location.reload();
    },
    buildVisitorInfo(v_json) {
      if (!v_json) return "";
      // console.log('this.getType(v_json)--->', this.getType(v_json))
      if (this.getType(v_json) == "String") {
        return v_json;
      } else {
        var reslut = "";
        if (!v_json.contact.email && !v_json.contact.phone) {
          reslut =
            "您好，由于您未提供访客联系方式，暂时无法完成访客邀请；如果您需要我帮您邀请，请提供访客邮箱或者手机号码。";
        } else {
          reslut =
            "您好，小智已经帮您诚挚地邀请" +
            v_json.contact.name +
            "于" +
            v_json.appointment.date +
            "，前往上海虹桥KAMPUS进行访问，邀请函已经通过短信或邮件形式发送给访客。";
          this.chatLast = "";
        }

        return reslut;
      }
    },
    getType(obj) {
      return Object.prototype.toString.call(obj).slice(8, -1);
    },
  },
};
</script>